import React from 'react'
import Project from '../portfolio/Projects/Project'
import { s, globals } from '../style'

const Subpages = ({ theme, data }) => {
  // console.log(data)

  return (
    <div css={sContainer}>
      <div css={sProjects}>
        {data?.subpages?.map((project, id) => (
          <Project key={id} id={id} data={project} theme={theme} />
        ))}
      </div>
    </div>
  )
}

const sContainer = {
  margin: '0 auto 4rem',
  maxWidth: globals.maxWidth + 64,
  [s.lg_1280]: {
    padding: '0 2rem',
  },
  marginBottom: '4rem',
  [s.md]: {
    padding: '0 2rem 4rem',
  },
  [s.sm_down]: {
    padding: '0 1.5rem 2rem',
  },
}

const sProjects = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '2rem',
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '1rem',
  },
}

export default Subpages
